/**
 * InterDynamics Cloud Web Application API
 * An ASP.NET Core Web API for Cloud Run Web Application
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ProjectUserRole = 'user' | 'manager' | 'admin' | 'read_only';

export const ProjectUserRole = {
    User: 'user' as ProjectUserRole,
    Manager: 'manager' as ProjectUserRole,
    Admin: 'admin' as ProjectUserRole,
    ReadOnly: 'read_only' as ProjectUserRole
};

