/**
 * InterDynamics Cloud Web Application API
 * An ASP.NET Core Web API for Cloud Run Web Application
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CloudTaskStatus = 'Created' | 'Submitted' | 'Active' | 'Complete' | 'Error' | 'Cancelled';

export const CloudTaskStatus = {
    Created: 'Created' as CloudTaskStatus,
    Submitted: 'Submitted' as CloudTaskStatus,
    Active: 'Active' as CloudTaskStatus,
    Complete: 'Complete' as CloudTaskStatus,
    Error: 'Error' as CloudTaskStatus,
    Cancelled: 'Cancelled' as CloudTaskStatus
};

